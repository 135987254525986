<template>
  <div>
    <div class="infotDetai">
      <form action="javascript:return true" @submit.prevent="formSubmit">
        <div class="search">
          <img src="../assets/img/publish/icon_news_search.png" />
          <input type="search" v-model.trim="searchKey" @keydown="searchTo($event)" :placeholder="placeholder" />
          <img @click="closeClick" v-show="close" src="../assets/img/information/icon_close.png" />
        </div>
      </form>
    </div>
    <div class="system-message-box">
      <mt-loadmore
        bottom-pull-text=""
        bottom-drop-text=""
        :bottom-distance="bottomDistance"
        :auto-fill="autoLoad"
        :bottom-method="loadBottom"
        :bottom-all-loaded="allLoaded"
        ref="loadmore"
      >
        <!-- 聚合搜索 -->
        <div class="listCt" @click="homeClick(item.id, item.type)" v-for="(item, index) in homeArr" :key="index">
          <div class="listLeft">
            <p class="title">【{{ item.typeName }}】 {{ item.title }}</p>
            <p class="time">{{ item.sourceName }} {{ item.dateTime }}</p>
          </div>
          <div class="listRight">
            <img
              v-if="item.summaryImg"
              :src="String(item.summaryImg).split(',')[0] + '?x-oss-process=image/resize,h_100'"
            />
          </div>
        </div>
        <!-- 资讯搜索 -->
        <div class="listCt" @click="inforClick(item.id)" v-for="(item, index) in listArr" :key="index">
          <div class="listLeft">
            <p class="title">【{{ item.categoryName }}】 {{ item.title }}</p>
            <p class="time">{{ item.authorName }}</p>
            <p class="time" style="margin-top:5px;">{{ item.createTime }}</p>
          </div>
          <div class="listRight">
            <img
              v-if="item.summaryImg"
              :src="String(item.summaryImg).split(',')[0] + '?x-oss-process=image/resize,h_100'"
            />
          </div>
        </div>
        <!-- 需求大厅 -->
        <div class="listCt" @click="inforClick(item.id)" v-for="(item, index) in projectArr" :key="index">
          <div class="listLeft">
            <p class="title">{{ item.title }}</p>
            <p class="time">{{ item.authorName }} {{ item.createTime }}- {{ item.endTime }}</p>
          </div>
          <div class="listRight">
            <img v-if="item.logo" :src="item.logo + '?x-oss-process=image/resize,h_100'" />
          </div>
        </div>

        <!-- 活动 -->
        <div class="listCt" @click="inforClick(item.id)" v-for="(item, index) in activityArr" :key="index">
          <div class="listLeft">
            <p class="title">{{ item.name }}</p>
            <p class="time">
              {{ item.startTime ? item.startTime.slice(5, 10) : ''
              }}{{ item.endTime ? `~${item.endTime.slice(5, 10)}` : '' }}
            </p>
            <p class="time">{{ item.addr }}</p>
          </div>
          <div class="listRight">
            <img v-if="item.imagePath" :src="item.imagePath + '?x-oss-process=image/resize,h_100'" />
            <div
              :class="{
                'activity-item__status': true,
                'activity-item__status-over': item.activityStatus === 2,
                'activity-item__status-join': item.activityStatus === 1,
                'activity-item__status-live': item.activityStatus === 0
              }"
            >
              {{ item.activityStatus_dictText }}
            </div>
          </div>
        </div>
      </mt-loadmore>
      <div class="load-all" v-show="allLoaded">{{ msg }}</div>
    </div>
  </div>
</template>

<script>
import { Indicator, Toast } from 'mint-ui' // 引入提示弹窗组件
import { newsSearch, HomeSearch, projectSearch, tbActivityList } from '@/api/public'
import { mapGetters } from 'vuex'
import { getCookie } from '@/utils/domUtil'
import { ACCESS_TOKEN } from '@/store/mutation-types'

export default {
  data() {
    return {
      close: false,
      searchKey: '',
      pageNo: 1, // 分页页数
      bottomDistance: 20, // 触发bottomMethod的上拉距离阈值（像素）
      autoLoad: false, // 是否自动加载数据参数
      allLoaded: false, // 数据是否加载完毕参数
      listArr: [],
      homeArr: [], // 聚合搜索
      projectArr: [], // 需求大厅
      activityArr: [], //  活动
      msg: '',
      istype: ''
    }
  },
  computed: {
    ...mapGetters(['type']),
    placeholder() {
      if (this.istype === 'news') {
        return '请输入资讯标题'
      } else if (this.istype === 'activity') {
        return '请输入活动标题'
      } else if (this.istype === 'home') {
        return '请输入项目、机构、资讯、活动'
      }
      return '请输入需求标题'
    }
  },
  watch: {
    searchKey(value) {
      if (value) {
        this.close = true
      } else {
        this.close = false
      }
    }
  },
  created() {
    this.istype = this.$route.query.type
  },
  methods: {
    formSubmit() {
      return false
    },
    searchTo() {
      this.pageNo = 1
      if (this.istype === 'news') {
        this.listArr = []
      }
      if (this.istype === 'home') {
        this.homeArr = []
      }
      if (this.istype === 'project') {
        this.projectArr = []
      }
      if (this.istype === 'activity') {
        this.activityArr = []
      }

      if (event.keyCode === 13) {
        this.infor(true)
        this.allLoaded = false
      }
    },
    // 详情页
    inforClick(id) {
      if (this.istype === 'news') {
        this.$router.push({
          path: '/news-detail',
          query: { id: id }
        })
      }
      if (this.istype === 'project') {
        const token = getCookie(ACCESS_TOKEN)
        if (token) {
          if (this.type !== 'org' && this.type !== 'employee') {
            this.errText = '您不是入驻机构，不能使用此服务'
            this.showError()
            return false
          } else {
            this.$router.push('/project/detail/' + id)
          }
        } else {
          this.$router.push({
            name: 'login'
          })
        }
      }

      if (this.istype === 'activity') {
        this.$router.push(`/activity/detail/${id}`)
      }
    },
    // 聚合搜索详情页
    homeClick(id, istype) {
      const _istype = String(istype).toUpperCase()
      if (_istype === 'ARTICLE') {
        this.$router.push({
          path: '/news-detail',
          query: { id: id }
        })
        return
      }
      if (_istype === 'PROJECT') {
        const token = getCookie(ACCESS_TOKEN)
        if (token) {
          if (this.type !== 'org' && this.type !== 'employee') {
            this.errText = '您不是入驻机构，不能使用此服务'
            this.showError()
            return false
          } else {
            this.$router.push('/project/detail/' + id)
          }
        } else {
          this.$router.push({
            name: 'login'
          })
        }
        return
      }
      if (_istype === 'ORG') {
        this.$router.push('/org/detail/' + id)
        return
      }

      if (_istype === 'ACTIVITY') {
        this.$router.push(`/activity/detail/${id}`)
        return
      }
      console.log(_istype)
    },
    // 搜索
    infor(flag) {
      if (!flag) Indicator.open('请稍等...')
      if (this.istype === 'news') {
        newsSearch({
          keyword: this.searchKey,
          pageSize: 10,
          pageNo: this.pageNo
        })
          .then(res => {
            this.msg = ''
            // if (res.respCode === 0) {
            const list = res.records.map(item => {
              item.createTime = item.createTime.slice(0, 10)
              item.endTime = item.endTime.slice(0, 10)
              return item
            })
            if (list.length > 0) {
              this.listArr = [...this.listArr, ...list]
              console.log(this.listArr)
            } else {
              this.msg = '暂无数据'
              this.allLoaded = true // 若数据已全部获取完毕
            }
            this.pageNo += 1
            // }
            if (flag) {
              this.$refs.loadmore.onBottomLoaded()
            } else {
              Indicator.close()
            }
          })
          .catch(err => {
            Indicator.close()
            this.requestFailed(err)
          })
      }
      if (this.istype === 'home') {
        HomeSearch({
          keyword: this.searchKey,
          pageSize: 10,
          pageNo: this.pageNo
        })
          .then(res => {
            this.msg = ''
            console.log(res)
            if (res.success) {
              const list = res.result.map(item => {
                item.createTime = item.createTime ? item.createTime.slice(0, 10) : ''
                item.endTime = item.endTime ? item.endTime.slice(0, 10) : ''
                return item
              })
              if (list.length > 0) {
                this.homeArr = [...this.homeArr, ...list]
              } else {
                this.msg = '暂无数据'
                this.allLoaded = true // 若数据已全部获取完毕
              }
              this.pageNo += 1
            }
            if (flag) {
              this.$refs.loadmore.onBottomLoaded()
            } else {
              Indicator.close()
            }
          })
          .catch(err => {
            Indicator.close()
            this.requestFailed(err)
          })
      }
      if (this.istype === 'project') {
        projectSearch({
          title: this.searchKey,
          pageSize: 10,
          auditStatus: 1,
          pageNo: this.pageNo
        })
          .then(res => {
            this.msg = ''
            if (res.success) {
              const list = res.result.records.map(item => {
                item.createTime = item.createTime.slice(0, 10)
                item.endTime = item.endTime.slice(0, 10)

                const imgTypes = ['.jpg', '.jpeg', '.png', '.gif']
                item.attachments.split(',').map(url => {
                  if (imgTypes.some(type => url.includes(type))) {
                    item.logo = url
                  }
                })

                return item
              })
              if (list.length > 0) {
                this.projectArr = [...this.projectArr, ...list]
                console.log(this.projectArr)
              } else {
                this.msg = '暂无数据'
                this.allLoaded = true // 若数据已全部获取完毕
              }
              this.pageNo += 1
            }
            if (flag) {
              this.$refs.loadmore.onBottomLoaded()
            } else {
              Indicator.close()
            }
          })
          .catch(err => {
            Indicator.close()
            this.requestFailed(err)
          })
      }

      if (this.istype === 'activity') {
        tbActivityList({
          name: this.searchKey,
          pageSize: 10,
          pageNo: this.pageNo
        })
          .then(res => {
            this.msg = ''
            if (res.success) {
              const list = res.result.records
              if (list.length > 0) {
                this.activityArr = [...this.activityArr, ...list]
              } else {
                this.msg = '暂无数据'
                this.allLoaded = true // 若数据已全部获取完毕
              }
              this.pageNo += 1
            }
            if (flag) {
              this.$refs.loadmore.onBottomLoaded()
            } else {
              Indicator.close()
            }
          })
          .catch(err => {
            Indicator.close()
            this.requestFailed(err)
          })
      }
    },
    // 上拉加载
    loadBottom() {
      setTimeout(() => {
        this.infor(true)
      }, 1000)
    },
    // 错误请求
    requestFailed(err) {
      console.error({
        message: '错误',
        description: ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试',
        duration: 4
      })
    },
    backClick() {
      this.$router.go(-1)
    },
    // 清除数据
    closeClick() {
      this.close = false
      this.searchKey = ''
    },
    showError() {
      Toast({
        message: this.errText,
        position: 'middle',
        duration: 5000
      })
    }
  }
}
</script>

<style lang="less" scoped>
.system-message-box {
  margin-top: 20px;
  padding: 0 15px;
  height: 90vh;
  overflow: scroll;
  .load-all {
    /* 数据加载完毕提示信息 */
    padding-bottom: 15px;
    font-size: 14px;
    text-align: center;
    color: #c8c8cf;
  }
}
.listCt {
  padding-bottom: 18px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 18px;
  .listLeft {
    width: 220px;
    margin-right: 26px;
    display: inline-block;
    .title {
      color: #333;
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 14px;
      display: -webkit-box;
      -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
      overflow: hidden;
      white-space: normal !important;
      text-overflow: ellipsis;
      word-wrap: break-word;
      -webkit-line-clamp: 2;
    }
    .time {
      font-size: 12px;
      color: #666;
    }
  }
  .listRight {
    position: relative;
    width: 90px;
    display: inline-block;
    vertical-align: top;
    border-radius: 5px;
    overflow: hidden;
    height: 65px;
    background: url(../assets/img/picture_default.png) no-repeat center center;
    background-size: cover;
    img {
      width: 90px;
      height: 65px;
      background-color: #fff;
    }
  }
}
.infotDetai {
  margin-top: 10px;
  padding: 0 15px;
  .search {
    height: 36px;
    overflow: hidden;
    line-height: 36px;
    padding-left: 18.5px;
    background: #f3f3f3;
    border-radius: 30px;
    vertical-align: middle;
    img {
      vertical-align: middle;
      padding-right: 0.5px;
    }
    input {
      border: none;
      width: 270px;
      background: #f3f3f3;
      vertical-align: middle;
      font-size: 15px;
      color: #333;
    }
    input::-webkit-search-decoration,
    input::-webkit-search-cancel-button {
      display: none;
    }
  }
}

.activity-item__status {
  background-color: #096dd9;
  color: #fff;
  line-height: 1;
  width: 40px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  font-style: normal;
}
.activity-item__status.activity-item__status-over {
  background-color: #c9c9c9;
}

.activity-item__status.activity-item__status-join {
  background-color: #fea515;
}

.activity-item__status.activity-item__status-live {
  background-color: #004e98;
}
</style>
